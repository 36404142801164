import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { ResultService } from '../_services/results.service';

declare var $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  	pageHeader = 'Kết Quả tìm Kiếm';
  	pager: any = {}; // pager object
    pagedItems: any[]; // paged items
    pageSize = 60;

    tintucs = [];
    ttCount = 0;
    noisans = [];
    nsCount = 0;
    albums = [];
    newAlbumImage: any[] = [];
    allImages: any[] = [];
    alCount = 0;
    lists= [];

  constructor(
	private rlt: ResultService,
	private db: DbApiService,
  	private router:Router, 
  	private _route: ActivatedRoute,
	private pagerService: PagerService
  ) { }

  ngOnInit() {
  	this.rlt.currentResults.subscribe(details=>{
  		// console.log(details['albums']);
      	if (details['albums'] == undefined || (details['albums'].length == 0 && details['noisans'].length == 0 && details['tintucs'].length == 0)){
      		this.router.navigate(['']);
      	}else{
      		this.tintucs = details['tintucs'];
      		this.noisans = details['noisans'];
      		this.albums = details['albums'];
      		// console.log(this.albums);  //detail is data you send in shared service
      		
  			this.ttCount = this.tintucs.length;
  			this.nsCount = this.noisans.length;
  			this.alCount = this.albums.length;

      		this.changelists(1);
      	}
   	})
  	
    // $.getScript('../../../assets/base/js/components.js');
  	$.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }

  	changelists(t){
  		this.pager = {}; // pager object
    	this.pagedItems = [];
  		this.lists = [];
  		if (t == 1){
  			this.lists = this.tintucs;
  			this.setPage(1);
  		}else if (t == 2){
  			this.lists = this.noisans;
  			this.setPage(1);
  		}else if (t == 3){
  			// this.getAllAlbumsImg();
  			this.lists = this.albums;
  			// this.setPage(1);
  		}
  	}

  	getAllAlbumsImg(){
      this.allImages = sessionStorage.getItem('images') ? JSON.parse(sessionStorage.getItem('images')) : [];
      if (this.allImages.length == 0){
          this.getAllImages();
      }else{
        this.getAlbumImage();
      }
    }

    getAllImages() {
      this.db.getAllImages()
      .subscribe( (response: Response) => {
          this.allImages = response.json();
          // console.log(this.allImages);
          sessionStorage.setItem('images',JSON.stringify(this.allImages));
          this.getAlbumImage();
      });
    }

  	getAlbumImage(){
  		console.log(this.allImages);
  		this.newAlbumImage = [];
      	for (var a = 0; a < this.albums.length; ++a) {
	        for (var i = 0; i < this.allImages.length; ++i) {
	          if (this.albums[a]['id_album'] == this.allImages[i]['id_album']){
	            var tempArr = [];
	            tempArr = this.albums[a];
	            // tempArr = this.allImages[i];
	            // tempArr['id'] = this.albums[a]['id_album'];
	            // tempArr['name'] = this.albums[a]['ten_album'];
	            // tempArr['email'] = this.allImages[i]['email'];
	            // tempArr['file'] = this.allImages[i]['tenfile'];
	            // console.log(tempArr);
	            this.newAlbumImage.push(tempArr);
	            break;
	          }
	        }
      	}
    	console.log(this.newAlbumImage);
    }
	
	setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.lists.length, page, this.pageSize);
        // console.log(this.pager);

        // get current page of items
        this.pagedItems = this.lists.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }
}
