import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch';
import { Sponsors, Tintuc } from "../api/classes";

@Injectable()
export class DbApiService {
	apiURL = "http://nsapi.nghiasinh.org/";
    nsApiURL = 'http://nsapi-details.nghiasinh.org/';
    // nsAppApiURL = 'http://nsapp_api.nghiasinh.org/';
  	constructor(
        private http: Http,
        // private _http:HttpClient
    ){}
      
    //Get Hợp Tác Viên
    getHTVs() {
        return this.http.get(this.apiURL + 'get_htvs');
    }
    getNBTs() {
        return this.http.get(this.apiURL + 'get_nbts');
    }
    //Get Thông Tin Đa Diện
    getTTDDs() {
        return this.http.get(this.apiURL + 'get_ttdds');
    }
    //Get Tin Tức Cập Nhật
    getTinTuc() {
        return this.http.get(this.apiURL + 'get_tintuc');
    }
    //Get Nội San Liên Nghĩa
    getNoiSan() {
        return this.http.get(this.apiURL + 'get_noisan');
    }
    //Get Hinh Ảnh
    getHinhAnhs() {
        return this.http.get(this.apiURL + 'get_hinhanhs');
    }
    getVideos() {
        return this.http.get(this.apiURL + 'get_videos');
    }
    getPowerPoints() {
        return this.http.get(this.apiURL + 'get_powerpoints');
    }

    //Get Nam Dieu Huong Nghiep
    getNamDieu() {
        return this.http.get(this.apiURL + 'get_namdieu');
    }

    // Mười Diều
    getMuoiDieu() {
        return this.http.get(this.apiURL + 'get_muoidieu');
    }

    // Sáng LẬp NS
    getSangLap() {
        return this.http.get(this.apiURL + 'get_sanglap');
    }

    getAboutUs() {
        return this.http.get(this.apiURL + 'get_aboutus');
    }

    // Get All Tin Tức Hội Họp
    getAllNews(){
        return this.http.get(this.apiURL + 'get_allnews');
    }
    getTintucById(ttId: string){ //: Observable<Tintuc[]> {
        return this.http.get(this.nsApiURL + 'get_tintucbyid.php?id='+ ttId);
    }

    getTintucUpdateById(ttId: string){ //: Observable<Tintuc[]> {
        return this.http.get(this.nsApiURL + 'get_tintuc_updatebyid.php?id='+ ttId);
    }

    // Get All Nội San Liên Nghĩa
    getAllNoiSan(){
        return this.http.get(this.apiURL + 'get_allnoisan');
    }
    getNoiSanById(id: string){
        return this.http.get(this.nsApiURL + 'get_noisanbyid.php?id='+ id);

    }

    // Get All Hoạt Động
    getAllHoatDong(){
        return this.http.get(this.apiURL + 'get_allhd');
    }

    // Get All Dự Án
    getAllDuAn(){
        return this.http.get(this.apiURL + 'get_allda');
    }

    // Get All Albums
    getAllAlbums(){
        return this.http.get(this.apiURL + 'get_allalbums');
    }
    getAllImages(){
        return this.http.get(this.apiURL + 'get_allimages');
    }
    getAImagesById(id: string){
        return this.http.get(this.nsApiURL + 'get_imagesbyalbumid.php?id='+ id);
    }

    // Get All Tu Điển
    getBoTuDien(){
        return this.http.get(this.apiURL + 'get_botudien');
    }
    getTuDien(){
        return this.http.get(this.apiURL + 'get_tudien');
    }

    // GET ALL USER ONLINE
    getUserOnline(){
        return this.http.get(this.apiURL + 'get_useronline');
    }
    getWebHits(){
        return this.http.get(this.apiURL + 'get_webhits');
    }

    private extractData(res: Response) {
        let body = res.json();
        return body;
    }
    private handleError (error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
