import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

declare var $: any;

@Component({
  selector: 'app-powerpoint',
  templateUrl: './powerpoint.component.html',
  styleUrls: ['./powerpoint.component.css']
})
export class PowerpointComponent implements OnInit {
	pageHeader = 'CÁC FILE PowerPoint';
	allPPs: any[] = [];
  	
  	constructor(
  		private pagerService: PagerService,
  		private db: DbApiService,
  		private http: Http
  	) { }

  	ngOnInit() {
  		this.allPPs = sessionStorage.getItem('pp') ? JSON.parse(sessionStorage.getItem('pp')) : [];
  		if (this.allPPs.length == 0){
  		  // console.log('empty');
  		  this.getPowerPoints();
  		}

      $.getScript('../../../assets/base/js/components.js');
		  $.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  	}

  	getPowerPoints() {
	 	this.db.getPowerPoints()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.allPPs = data;
          sessionStorage.setItem('pp', JSON.stringify(data));
    		// console.log(this.allVideos);
    		
      	});
  	}

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
