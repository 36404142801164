import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
declare var $: any;

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnInit {

	allAlbums: any[] = [];
    album = [];
    imagesArr = [];
    newImagesArr = [];
    albumId = '';
    newAlbumsArr = [];
    pageHeader = '';
    pager: any = {}; // pager object
    pagedItems: any[]; // paged items
    pageSize = 60;

  	constructor(
  		private pagerService: PagerService,
      private route: ActivatedRoute,
  		private db: DbApiService,
  		private http: Http
  	) { }

  	ngOnInit() {
      this.route.params.subscribe((params: Params) => {
        this.albumId = params['id'];
        this.imagesArr = sessionStorage.getItem('images') ? JSON.parse(sessionStorage.getItem('images')) : [];
        if (this.imagesArr.length == 0){
          // console.log('empty');
          this.getAllImages(this.albumId);
        }else{
          this.getImagesById(this.albumId);
        }  
      });

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

    getAllImages(albumId) {
      this.db.getAllImages()
      .subscribe( (response: Response) => {
          this.imagesArr = response.json();
          sessionStorage.setItem('images',JSON.stringify(this.imagesArr));
          this.getImagesById(this.albumId);
      });
    }

    getImagesById(alId){
    	// console.log(alId);
    	this.newImagesArr = [];
      	for (var i = 0; i < this.imagesArr.length; ++i) {
	        if (this.imagesArr[i]['id_album'] ==  alId){
	          this.newImagesArr.push(this.imagesArr[i]);
	        }
      	}
      	this.allAlbums = sessionStorage.getItem('albums') ? JSON.parse(sessionStorage.getItem('albums')): [];
      	if (this.allAlbums.length == 0){
	        this.getAllAlbums(alId);
      	}else{
      		this.getAlbumsById(alId);
      	}
  		for (var j = 0; j < this.allAlbums.length; ++j) {
	        if (this.allAlbums[j]['id_album'] == alId){
	          this.album = this.allAlbums[j];
	        }
      	}
      	this.pageHeader = this.album['ten_album'];
      	// console.log(this.newImagesArr);
      	// this.setPage(1);
    }

  	getAllAlbums(alId) {
      this.db.getAllAlbums()
      .subscribe( (response: Response) => {
          this.allAlbums = response.json();
          // console.log(this.allAlbums);
          sessionStorage.setItem('albums',JSON.stringify(this.allAlbums));
          this.getAlbumsById(alId);
      });
    }

    getAlbumsById(aId){
    	this.newAlbumsArr = [];
    	for (var a = 0; a < this.allAlbums.length; ++a) {
    		if (this.allAlbums[a]['id_album'] != aId){
    			this.newAlbumsArr.push(this.allAlbums[a]);
    		}
    	}
    }

	  setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.newImagesArr.length, page, this.pageSize);
        console.log(this.pager);
        // get current page of items
        this.pagedItems = this.newImagesArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
