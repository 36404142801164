import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-huychuong',
  templateUrl: './huychuong.component.html',
  styleUrls: ['./huychuong.component.css']
})
export class HuychuongComponent implements OnInit {
  	pageHeader = 'Huy Chương và Tưởng Lệ';
  	constructor() { }

  	ngOnInit() {
      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}
}
