import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
	isPage1 = true;
	isPage2 = false;
	isPage3 = false;
  	constructor() { }

  	ngOnInit() {
      // $.getScript('../../../assets/base/js/components.js');
      // $.getScript('../../../assets/base/js/app.js');
      $.getScript('../../../assets/base/js/custom.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	nextpage(p){
  		if (p == 1){
  			this.isPage1 = true;
  			this.isPage2 = false;
  			this.isPage3 = false;
  		}else if (p == 2){
  			this.isPage1 = false;
  			this.isPage2 = true;
  			this.isPage3 = false;
  		}else if (p == 3){
  			this.isPage1 = false;
  			this.isPage2 = false;
  			this.isPage3 = true;
  		}
      window.scrollTo(0, 0);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
