import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { Resolve } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { DbApiService } from './_services/dbapi.service';
import { ResultService } from './_services/results.service';
import { LibrariesService } from './_services/lib.service';
import { EmailService, IMessage } from './_services/email.service';


// declare var $: any;
import * as $ from 'jquery';
import { TuDien, TimKiem } from './api/classes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	private currentRoute = '';
	
	botudiens: any[] = [];
	tudiens: any[] = [];
	wordPattern = "^[a-z0-9_-]{2,20}$";
	isSend = false;
	sendStatus = '';

	allnews: any[] = [];
	allnoisan: any[] = [];
	allttdd: any[] = [];
	allAlbums: any[] = [];

	imessage: IMessage = {};

	total_bv = 0;
	total_al = 0;
	total_on = 0;
	total_tc = 0;

	theloais = [
				{id:1, name: 'Tiêu Đề', keyword: 'tieude'},
				{id:2, name: 'Nội Dung Tóm Tắt', keyword: 'tomtat'},
				{id:3, name: 'Bí Danh / Tác Giả', keyword: 'tacgia'}
				];

	isValidWord = false;
	isValidDictionary = false;

	isValidTuKhoa = false;
	isValidTheLoai = false;

	tudienForm = this.formBuilder.group({
	    word: [''],
	    dictionary: [''],
	});

	timkiemForm = this.formBuilder.group({
	    tukhoa: [''],
	    theloai: [''],
	});

	searchForm = this.formBuilder.group({
	    searchkey: [''],
	});

	contactForm = this.formBuilder.group({
		name: '',
		subject: '',
		email: '',
		phone: '',
		message: '',
	})
	results = [];

	constructor(
		public router: Router,
		private db: DbApiService,
		private rlt: ResultService,
		private formBuilder:FormBuilder,
  		private http: Http,
  		private requestOptions: RequestOptions,
  		private _email: EmailService,
  		private _lib: LibrariesService
	){
		router.events.subscribe(event => {
	      	if (event instanceof NavigationEnd ) {
	      		this.currentRoute = (event.url).replace('/','');
	      		var indx = this.currentRoute.indexOf('/');
	      		if (indx > 0){
	      			this.currentRoute = this.currentRoute.substring(0,indx);
	      			// console.log(this.currentRoute);
	      		}
		    };
	    });
	}  

	//Init method
	ngOnInit(){

	    this.tudiens = sessionStorage.getItem('tudiens') ? JSON.parse(sessionStorage.getItem('tudiens')) : [];
		if (this.tudiens.length == 0){
	    	this.getTuDien();
	    }
	    this.botudiens = sessionStorage.getItem('botudiens') ? JSON.parse(sessionStorage.getItem('botudiens')) : [];
		if (this.botudiens.length == 0){
	    	this.getBoTuDien();
	    }

	    this.allnews = sessionStorage.getItem('tintucs') ? JSON.parse(sessionStorage.getItem('tintucs')) : [];
  		if (this.allnews.length == 0){
			  // console.log('empty');
			  this.getAllNews();
  		}
  		this.allnoisan = sessionStorage.getItem('noisans') ? JSON.parse(sessionStorage.getItem('noisans')) : [];
		if (this.allnoisan.length == 0){
			// console.log('empty');
			this.getAllNoiSan();
		}
  		this.allttdd = sessionStorage.getItem('ttdds') ? JSON.parse(sessionStorage.getItem('ttdds')) : [];
		if (this.allttdd.length == 0){
			// console.log('empty');
			this.getAllTTDDs();
		}else{
			this.getTotalBV();
		}
		this.allAlbums = sessionStorage.getItem('albums') ? JSON.parse(sessionStorage.getItem('albums')) : [];
  		if (this.allAlbums.length == 0){
			  // console.log('empty');
			  this.getAllAlbums();
  		}else{
  			this.total_al = this.allAlbums.length;
  		}

  		this.getWebHits();
  		this.getUserOnline();

  		
  		
  		this._lib.updatesessionStorage();
  		
	}	

	ngAfterContentInit(){
	   	$.getScript('../../../assets/base/js/components.js');
		$.getScript('../../../assets/base/js/app.js');
		$.getScript('../../../assets/base/js/custom.js');
  	}

	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}

	showMegaMenu(){
		// console.log('app');
		if ($('body').hasClass('c-mega-menu-shown')){
			$('body').removeClass('c-mega-menu-shown');
			$('.c-mega-menu').removeClass('c-shown');
		}else{
			$('.c-layout-header-fixed').addClass('c-mega-menu-shown');
			$('.c-mega-menu').addClass('c-shown');
		}
	}

	showExpandedMenu(){
		if ($('body').hasClass('c-topbar-expanded')){
			$('body').removeClass('c-topbar-expanded');
		}else{
			$('body').addClass('c-topbar-expanded');
		}
	}

	// BEGIN THỐNG KÊ
	getTotalBV(){
		this.total_bv =	this.allnews.length + this.allnoisan.length + this.allttdd.length;
	}

  	getAllNews() {
	 	this.db.getAllNews()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          	this.allnews = data;
          	sessionStorage.setItem('tintucs', JSON.stringify(data));
    		// console.log(this.allnews);
    		if (this.allnews.length > 0 && this.allnoisan.length > 0 && this.allttdd.length > 0){
   				this.getTotalBV();
    		}
      	});
  	}

  	getAllNoiSan() {
 		this.db.getAllNoiSan()
          .map((response: Response) => response.json())
          .subscribe(data => {
              	this.allnoisan = data;
              	sessionStorage.setItem('noisans', JSON.stringify(data));
      			// console.log(this.allnoisan);
	    		if (this.allnews.length > 0 && this.allnoisan.length > 0 && this.allttdd.length > 0){
    				this.getTotalBV();
	    		}
      	});
	}

	getAllTTDDs() {
		this.db.getTTDDs()
	  	.subscribe( (response: Response) => {
	      	this.allttdd = response.json();
		    sessionStorage.setItem('ttdds',JSON.stringify(this.allttdd));
		  	// console.log(this.allttdd.length);
    		if (this.allnews.length > 0 && this.allnoisan.length > 0 && this.allttdd.length > 0){
    			this.getTotalBV();
    		}
	  });
	}

	getAllAlbums() {
		this.db.getAllAlbums()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.allAlbums = data;
          sessionStorage.setItem('albums', JSON.stringify(data));
      		// console.log(this.allAlbums);
      		this.total_al = this.allAlbums.length;
      });
  	}

  	getWebHits() {
		this.db.getWebHits()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          	// set items to json response
          	this.total_tc = data[0]['dem'];
      		// console.log(this.total_tc);
      });
  	}

  	getUserOnline() {
		this.db.getUserOnline()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          	// set items to json response
          	this.total_on = data.length;
      		// console.log(this.total_on);
      });
  	}
	// END THỐNG KÊ

	// BEGIN: TU DIEN
	getTuDien() {
		this.db.getTuDien()
	  	.subscribe( (response: Response) => {
	      this.tudiens = response.json();
	      // console.log(this.tudien);
	      sessionStorage.setItem('tudiens',JSON.stringify(this.tudiens));
	  });
	}
	getBoTuDien() {
		this.db.getBoTuDien()
	  	.subscribe( (response: Response) => {
	      this.botudiens = response.json();
	      // console.log(this.botudien);
	      sessionStorage.setItem('botudiens',JSON.stringify(this.botudiens));
	  });
	}

	onTuDienFormSubmit() {
		let wordForm = this.tudienForm.controls['word'].value;
		let dictionaryForm = this.tudienForm.controls['dictionary'].value;
		if(wordForm == ""){
	    	this.isValidWord = true;
	    	return;
	  	} else {
			let word: TuDien = wordForm;
			this.isValidWord = false;
      	}
      	if(dictionaryForm == ""){
	    	this.isValidDictionary = true;
	    	return;
	  	} else {
			let dictionary: TuDien = dictionaryForm;
			this.isValidDictionary = false;
      	}
		console.log(this.tudienForm.value);
		window.open("https://vdict.com/"+wordForm+","+dictionaryForm+",0,0.html", "_blank");
		// this.router.navigate(["/"]).then(result=>{window.location.href = 'https://vdict.com/'+wordForm+','+dictionaryForm+',0,0.html', "_blank";});
		// this.userService.createUser(user);
		this.tudienForm.reset();
  	}
  	get word() {
     return this.tudienForm.get('word');
  	}
  	get dictionary() {
     return this.tudienForm.get('dictionary');
  	}
  	// END: TU DIEN

  	// BEGIN: TÌM KIẾM
  	onSearchFormSubmit(){
  		let searchKey = this.searchForm.controls['searchkey'].value;
  		this.getAllResults('tieude', searchKey);
  	}

  	onTimKiemFormSubmit(){
  		let tuKhoaForm = this.timkiemForm.controls['tukhoa'].value;
		let theLoaiForm = this.timkiemForm.controls['theloai'].value;
		if(tuKhoaForm == "" || tuKhoaForm == null){
	    	this.isValidTuKhoa = true;
	    	return;
	  	} else {
			let tukhoa: TimKiem = tuKhoaForm;
			this.isValidTuKhoa = false;
      	}
      	if(theLoaiForm == "" || theLoaiForm == null){
	    	this.isValidTheLoai = true;
	    	return;
	  	} else {
			let theloai: TimKiem = theLoaiForm;
			this.isValidTheLoai = false;
      	}
		// console.log(this.timkiemForm.value);
		this.getAllResults(theLoaiForm, tuKhoaForm);
  	}

  	getAllResults(t,k){
  		this.results['tintucs'] = [];
		this.results['noisans'] = [];
		this.results['albums'] = [];
		
      	if ((k != '') && (t != '')){
      		this.results['tintucs'] = this.getResults(this.allnews, t, k, 1);
      		this.results['noisans'] = this.getResults(this.allnoisan, t, k, 2);
      		this.results['albums'] = this.getResults(this.allAlbums, t, k, 3);
      	}
      	// console.log(this.results);
      	this.router.navigate(['/search']);
      	this.rlt.setResults(this.results);
		this.timkiemForm.reset();
  	}

  	get tukhoa() {
     return this.timkiemForm.get('tukhoa');
  	}
  	get theloai() {
     return this.timkiemForm.get('theloai');
  	}

  	getResults(list, type, key, opt){
  		let convt = '';
  		let newkey = this._lib.change_alias(key);
  		let res = [];
  		let tgia = '';
  		let tde = '';
  		for (var n = 0; n < list.length; ++n) {
  			if (type == 'tieude'){
  				if (opt == 3){
  					tde = list[n]['ten_album'];
  				}else{
  					tde = list[n]['tieude'];
  				}
  				convt = this._lib.change_alias(tde);
  				if (convt.indexOf(newkey) >= 0){
  					res.push(list[n]);
  				}
  			}else if (type == 'tomtat'){
  				if (opt == 1 || opt == 2){
	  				convt = this._lib.change_alias(list[n]['tomtat']);
	  				if (convt.indexOf(newkey) >= 0){
	  					res.push(list[n]);
	  				}
  				}
  			}else if (type == 'tacgia'){
  				if (opt == 1){
  					tgia = list[n]['bidanh'];
  				}else{
  					tgia = list[n]['tacgia'];
  				}
  				if (opt == 1 || opt == 2){
	  				convt = this._lib.change_alias(tgia);
	  				if (convt.indexOf(newkey) >= 0){
	  					res.push(list[n]);
	  				}
	  			}
  			}
  		}
  		return res;
  	}
  	// END: TÌM KIẾM

  	// BEGIN: SEND MAIL
  	isValidName = false;
  	isValidEmail = false;
  	isValidSubject = false;
  	isValidPhone = false;
  	isValidMessage = false;
  	onContactFormSubmit(){
  		let fromname = this.contactForm.controls['name'].value;
  		let fromemail = this.contactForm.controls['email'].value;
  		let fromsubject = this.contactForm.controls['subject'].value;
  		let fromphone = this.contactForm.controls['phone'].value;
  		let frommessage = this.contactForm.controls['message'].value;
  		if (fromname == "" || fromname == null){
	    	this.isValidName = true;
	    	return;
	  	} else {
			this.imessage.name = fromname;
			// let name: IMessage = fromname;
			this.isValidName = false;
      	}

      	if (fromemail == "" || fromemail == null){
	    	this.isValidEmail = true;
	    	return;
	  	} else {
			// let email: IMessage = fromemail;
			this.imessage.email = fromemail;
			this.isValidEmail = false;
      	}
      	
      	if(fromsubject == "" || fromsubject == null){
	    	this.isValidSubject = true;
	    	return;
	  	} else {
			// let subject: IMessage = fromsubject;
			this.imessage.subject = fromsubject;
			this.isValidSubject = false;
      	}
      	
      	if(fromphone == "" || fromphone == null){
	    	// this.isValidPhone = true;
	    	// return;
	    	this.imessage.phone = '';
	  	} else {
			// let phone: IMessage = fromphone;
			this.imessage.phone = fromphone;
			this.isValidPhone = false;
      	}

      	if(frommessage == "" || frommessage == null){
	    	this.isValidMessage = true;
	    	return;
	  	} else {
			// let message: IMessage = frommessage;
			this.imessage.message = frommessage;
			this.isValidMessage = false;
      	}

      	this.sendEmail(this.imessage.email, this.imessage.name, this.imessage.subject, this.imessage.message, this.imessage.phone);
  	}

	sendEmail(email, name, subject, message, phone) {
	 	this._email.sendEmail(email, name, subject, message, phone)
	      .map((response: Response) => response.json())
	      .subscribe(data => {
	        // set items to json response
	        let status = data['status'];
	        if (data['status'] == 'success'){
	        	this.isSend = true;
	        	this.sendStatus = 'Your message has been sent successfully';
	        }else{
	        	this.isSend = false;
	        	this.sendStatus = 'Your message has not been sent';

	        }
	        // console.log(data);
	  	});
	}
  	// END: SEND MAIL

}
