import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	pageHeader = 'Liên Lạc Nghĩa Sinh';
  	constructor() { }

  	ngOnInit() {
  		$.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  		$.getScript('../../../assets/plugins/gmaps/gmaps.js');
  		$.getScript('../../../assets/base/js/contact.js');
  	}

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }

}
