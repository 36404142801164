import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutvn',
  templateUrl: './aboutvn.component.html',
  styleUrls: ['./aboutvn.component.css']
})
export class AboutvnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
	// $.getScript('../../../assets/base/js/components.js');
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }
}
