import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	// $.getScript('../../../assets/base/js/components.js');
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }
}
