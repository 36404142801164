import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-sanglap',
  templateUrl: './sanglap.component.html',
  styleUrls: ['./sanglap.component.css']
})
export class SanglapComponent implements OnInit {

	  pageTitle = 'Sáng Lập Nghĩa SINH';
  	sanglap: any[] = [];
    slapnoidung: string;
  	constructor(
  		private db: DbApiService
  	) { }

  	ngOnInit() {
		  this.getSangLaps();

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

	  getSangLaps() {
  		this.db.getSangLap()
  	  	.subscribe( (response: Response) => {
  	      this.sanglap = response.json();
          this.slapnoidung = this.sanglap[0]['noidung'];
  	      this.pageTitle = this.sanglap[0]['tieude'];
  	      // console.log(this.sanglap);
  	  	});
  	}

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
