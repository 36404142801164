import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { OwlCarousel } from 'ngx-owl-carousel';
// import { Lightbox } from 'angular2-lightbox';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service'
declare var $: any;

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.css']
})
export class PicturesComponent implements OnInit {
	allImages: any[] = [];
	allAlbums: any[] = [];
	pageHeader = 'Thư Viện Ảnh';

	pager: any = {}; // pager object
    pagedItems: any[]; // paged items
    pageSize = 44;

  	sliderOptions = { margin: 20, dots: true, navigation: true, nav: false, navText:['<', '>'], autoplay: true, loop: true, lazyLoad: true, responsive:{ 0:{ items: 1 }, 600:{ items: 2 }, 1000:{ items: 5 } } };

  	constructor(
  		private pagerService: PagerService,
  		private db: DbApiService,
  		// private _lightbox: Lightbox,
  		private route: ActivatedRoute
  	) { }

  	ngOnInit() {
  		this.allImages = sessionStorage.getItem('images') ? JSON.parse(sessionStorage.getItem('images')) : [];
  		if (this.allImages.length == 0){
      		this.getAllImages();
  		}else{
  			this.setPage(1);
  		}
    	this.allAlbums = sessionStorage.getItem('albums') ? JSON.parse(sessionStorage.getItem('albums')) : [];
  		if (this.allAlbums.length == 0){
      		this.getAllAlbums();
  		}

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
    }

  	getAllImages() {
  		this.db.getAllImages()
  		.subscribe( (response: Response) => {
      		this.allImages = response.json();
      		// console.log(this.allImages);
	      	sessionStorage.setItem('images',JSON.stringify(this.allImages));
	      	this.setPage(1);
  		});
  	}

  	getAllAlbums() {
		  this.db.getAllAlbums()
  		.subscribe( (response: Response) => {
      		this.allAlbums = response.json();
      		// console.log(this.allAlbums);
	      	sessionStorage.setItem('albums',JSON.stringify(this.allAlbums));

  		});
  	}

  	setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.allImages.length, page, this.pageSize);
        // console.log(this.pager);
        // get current page of items
        this.pagedItems = this.allImages.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
