import { Component, OnInit, ViewChild, NgModule, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Http, Response, Headers } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
 	allhds: any[] = [];
	pageHeader = 'Thành quả hoạt động';
  	hdTen: string;
  	noidung: string;
	hinhanh: string;
	chuthich: string;
	ngay: string;
	hdId: number;
	hdArr: any[] = [];
	selectedHD: any[] = [];
	hoatdongArr = [];
	constructor(
	  	private route: ActivatedRoute,
		private db:DbApiService, 
		private http: Http
	) {

	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
        	this.hdId = params['id'];
        	// console.log('get from  link: '+this.hdId);
			
			this.hdArr = sessionStorage.getItem('hoatdong') ? JSON.parse(sessionStorage.getItem('hoatdong')) : [];
			if (this.hdArr.length == 0){
			  	// console.log('empty');
			  	this.getAllHoatDong();
			}else{
				// console.log(this.hdArr);
				this.allhds = this.hdArr;
				this.hdId = this.getHDId();
				this.renderHoatDong(this.hdId);
				// console.log('not empty');
			}

    	});
      	// $.getScript('../../../assets/base/js/components.js');
		// $.getScript('../../../assets/base/js/app.js');
		$.getScript('../../../assets/base/js/single-page.js');
	}

	getAllHoatDong() {
	  	this.db.getAllHoatDong()
    	.map((response: Response) => response.json())
    	.subscribe(data => {
        	// set items to json response
        	this.allhds = data;
        	sessionStorage.setItem('hoatdong', JSON.stringify(data));
  			// console.log(this.allhds);
			this.hdId = this.getHDId();
			this.renderHoatDong(this.hdId);
			// console.log('getAllHoatDong')
    	});
	}

 	public renderHoatDong(id){
 		this.hoatdongArr = [];
 		// console.log('render info:'+id);
 		for (var d = 0; d < this.allhds.length; ++d) {
 			if (this.allhds[d]['id_hd'] == id){
 				this.selectedHD = this.allhds[d];
 				this.hdTen = this.selectedHD['ten'];
			  	this.noidung = this.selectedHD['thongtin'];
			  	this.hinhanh = this.selectedHD['hinhanh'];
			  	this.chuthich = this.selectedHD['chuthich'];
			  	this.ngay = this.selectedHD['ngay'];
 			}else{
 				this.hoatdongArr.push(this.allhds[d]);
 			}
 		}
 	}

 	getHDId(){
 		if (this.hdId == 0){
        	return this.allhds[0]['id_hd'];
        }else{
        	return this.hdId;
        }
 	}

 	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}
}
