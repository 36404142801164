import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

declare var $: any;

@Component({
  selector: 'app-ttdd',
  templateUrl: './ttdd.component.html',
  styleUrls: ['./ttdd.component.css']
})
export class TTDDsComponent implements OnInit {
	pageHeader = 'Thông Tin Đa Diện';
	allTTDDs: any[] = [];

  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 50;
  currentPage = 0;
  	
  	constructor(
  		private pagerService: PagerService,
  		private db: DbApiService,
  		private http: Http
  	) { }

  	ngOnInit() {
  		this.allTTDDs = sessionStorage.getItem('ttdds') ? JSON.parse(sessionStorage.getItem('ttdds')) : [];
  		if (this.allTTDDs.length == 0){
  		  // console.log('empty');
  		  this.getTTDDs();
      }else{
        this.setPage(1);
  		}

      $.getScript('../../../assets/base/js/components.js');
		  $.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  	}

  	getTTDDs() {
	 	this.db.getTTDDs()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.allTTDDs = data;
          sessionStorage.setItem('ttdds', JSON.stringify(data));
    		  // console.log(this.allVideos);
          this.setPage(1);
      	});
  	}

    setPage(page: number) {
      console.log(this.allTTDDs.length)
        // get pager object from service
        this.pager = this.pagerService.getPager(this.allTTDDs.length, page, this.pageSize);
        // console.log(this.pager);
        this.currentPage = page;
        // get current page of items
       this.pagedItems = this.allTTDDs.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
