import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DataTableModule } from 'angular-4-data-table-bootstrap-4';
import { HttpModule  } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';

import { AppComponent } from './app.component';
import { OwlModule } from 'ngx-owl-carousel';
// import { CarouselModule } from 'angular4-carousel';
// import { LightboxModule } from 'angular2-lightbox';
import { Ng2PageScrollModule } from 'ng2-page-scroll';

import { PagerService } from './_services/pager.service'
import { DbApiService } from './_services/dbapi.service';
import { ResultService } from './_services/results.service';
import { EmailService } from './_services/email.service';
import { LibrariesService } from './_services/lib.service';

// import { appRoutes } from './app.routes';

import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { TintucComponent } from './tintuc/tintuc.component';
import { NoisansComponent } from './noisans/noisans.component';
import { NoisanComponent } from './noisan/noisan.component';
import { TTDDsComponent } from './ttdd/ttdd.component';
import { AboutvnComponent } from './aboutvn/aboutvn.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { HistoryComponent } from './history/history.component';
import { NeovaoComponent } from './neovao/neovao.component';
import { NamdieuComponent } from './namdieu/namdieu.component';
import { MuoidieuComponent } from './muoidieu/muoidieu.component';
import { DieuleComponent } from './dieule/dieule.component';
import { QuyluatComponent } from './quyluat/quyluat.component';
import { TuyentapComponent } from './tuyentap/tuyentap.component';
import { SanglapComponent } from './sanglap/sanglap.component';
import { HuychuongComponent } from './huychuong/huychuong.component';
import { SuccessComponent } from './success/success.component';
import { ProjectsComponent } from './projects/projects.component';
import { PicturesComponent } from './pictures/pictures.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { AlbumComponent } from './album/album.component';
import { AlbumsComponent } from './albums/albums.component';
import { PowerpointComponent } from './powerpoint/powerpoint.component';
import { VideosComponent } from './videos/videos.component';
import { SearchComponent } from './search/search.component';

const appRoutes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  // {path: '#c-lienlac', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'tintucs', component: NewsComponent},
  {path: 'tintuc/:id', component: TintucComponent},
  {path: 'new_nd/:id', redirectTo: 'tintuc/:id', pathMatch: 'full'},
  {path: 'noisans', component: NoisansComponent},
  {path: 'ttdds', component: TTDDsComponent},
  {path: 'noisan/:id', component: NoisanComponent},
  {path: 'thanhqua/:id', component: SuccessComponent},
  {path: 'duan/:id', component: ProjectsComponent},
  {path: 'albums', component: AlbumsComponent},
  {path: 'album/:id', component: AlbumComponent},
  {path: 'pictures', component: PicturesComponent},
  {path: 'powerpoint', component: PowerpointComponent},
  {path: 'videos', component: VideosComponent},
  {path: 'aboutvn', component: AboutvnComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'history', component: HistoryComponent},
  {path: 'neovao', component: NeovaoComponent},
  {path: 'namdieu', component: NamdieuComponent},
  {path: 'muoidieu', component: MuoidieuComponent},
  {path: 'dieule', component: DieuleComponent},
  {path: 'quyluat', component: QuyluatComponent},
  {path: 'tuyentap', component: TuyentapComponent},
  {path: 'sanglap', component: SanglapComponent},
  {path: 'huychuong', component: HuychuongComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'search', component: SearchComponent},
  // {path: '**', component: HomeComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NewsComponent,
    SuccessComponent,
    ProjectsComponent,
    PicturesComponent,
    BlogComponent,
    AboutvnComponent,
    AboutusComponent,
    HistoryComponent,
    NeovaoComponent,
    NamdieuComponent,
    MuoidieuComponent,
    DieuleComponent,
    QuyluatComponent,
    TuyentapComponent,
    SanglapComponent,
    HuychuongComponent,
    ContactComponent,
    NoisanComponent,
    NoisansComponent,
    TTDDsComponent,
    TintucComponent,
    AlbumComponent,
    AlbumsComponent,
    VideosComponent,
    PowerpointComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    OwlModule,
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot (appRoutes, { useHash: true }),
    Ng2PageScrollModule
  ],
  providers: [
    DbApiService,
    ResultService,
    PagerService,
    EmailService,
    LibrariesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
