import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

@Injectable()
export class ResultService {

    private result = new BehaviorSubject<any>([]);

    currentResults = this.result.asObservable();
    setResults(result?: any) {
        this.result.next(result);
    }
}