import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-muoidieu',
  templateUrl: './muoidieu.component.html',
  styleUrls: ['./muoidieu.component.css']
})
export class MuoidieuComponent implements OnInit {
    pageHeader = '';
  	muoidieus: any[] = [];
    noidung: string;
  	constructor(
  		private db: DbApiService
  	) { }

  	ngOnInit() {
		  this.getMuoiDieu();

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	getMuoiDieu() {
  		this.db.getMuoiDieu()
  	  	.subscribe( (response: Response) => {
  	      this.muoidieus = response.json();
  	      this.noidung = this.muoidieus[0]['noidung'];
          this.pageHeader = this.muoidieus[0]['tieude'];
  	      // console.log(this.muoidieus);
  	  });
	  }
    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
