import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-dieule',
  templateUrl: './dieule.component.html',
  styleUrls: ['./dieule.component.css']
})
export class DieuleComponent implements OnInit {
	pageHeader = 'ĐIỀU Lệ nghĩa SINH';
  	constructor() { }

  	ngOnInit() {
      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	ngAfterViewInit(){
	    window.scrollTo(0, 0);
	  }
}
