import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  	allnews: any[] = [];
  	pageHeader = 'Tin Tức & Hội Họp';
    pager: any = {}; // pager object
    pagedItems: any[]; // paged items
    pageSize = 60;
    tintucArr = [];
    currentPage = 0;
  	constructor(
  		private pagerService: PagerService,
  		private db: DbApiService,
  		private http: Http
  	) { }

  	ngOnInit() {
  		this.allnews = sessionStorage.getItem('tintucs') ? JSON.parse(sessionStorage.getItem('tintucs')) : [];
  		if (this.allnews.length == 0){
			  // console.log('empty');
			  this.getAllNews();
  		}else{
			  this.setPage(1);
  		}
      
      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  	}

  	getAllNews() {
	 	  this.db.getAllNews()
      .map((response: Response) => response.json())
      .subscribe(data => {
          // set items to json response
          this.allnews = data;
          sessionStorage.setItem('tintucs', JSON.stringify(data));
    		// console.log(this.allnews);
    		
    		// initialize to page 1
			  this.setPage(1);
      });
	  }

	  setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.allnews.length, page, this.pageSize);
        // console.log(this.pager);
        this.currentPage = page;

        // get current page of items
        this.pagedItems = this.allnews.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
