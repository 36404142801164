import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
declare var $: any;
// import { NoiSan } from '../api/classes';

@Component({
  selector: 'app-noisan',
  templateUrl: './noisan.component.html',
  styleUrls: ['./noisan.component.css']
})
export class NoisanComponent implements OnInit {
  noisan: any[] = [];
	pageHeader = 'Nội San Liên Nghĩa - Details';
  nsId = '';
  tomtat = '';
  hits = '';
  chuthich = '';
  ngay = '';
  tacgia = '';
  hinhanh = '';
  noidung: string;
  errorMessage: string;
  dataAvailableById = true;
  noisans = [];
	constructor(
    private route: ActivatedRoute,
		private db: DbApiService,
		private http: Http
	) { }

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
      this.nsId = params['id'];
      this.getNoiSanById(this.nsId);
      this.noisans = sessionStorage.getItem('noisans') ? JSON.parse(sessionStorage.getItem('noisans')): [];
      if (this.noisans.length == 0){
        this.getAllNoiSan();
      }
    });

    // $.getScript('../../../assets/base/js/components.js');
    $.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  }

  getAllNoiSan() {
       this.db.getAllNoiSan()
      .map((response: Response) => response.json())
      .subscribe(data => {
          // set items to json response
          this.noisans = data;
          sessionStorage.setItem('noisans', JSON.stringify(data));
        // console.log(this.allnoisan);
      });
  }

  getNoiSanById(id: string) {
     this.db.getNoiSanById(id)
      .map((response: Response) => response.json())
      .subscribe(data => {
        // set items to json response
        this.noisan = data[0];
        this.pageHeader = this.noisan['tieude'];
        this.noidung = this.noisan['noidung'];
        this.hinhanh = this.noisan['hinhanh'];
        this.tacgia = this.noisan['tacgia'];
        this.chuthich = this.noisan['chuthich'];
        this.tomtat = this.noisan['tomtat'];
        this.hits = this.noisan['hits'];
        this.ngay = this.noisan['ngay'];
        // console.log(this.noisan);
      });
  }

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }
}
