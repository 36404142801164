import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Http, Response, Headers } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { OwlCarousel } from 'ngx-owl-carousel';
// import { Lightbox } from 'angular2-lightbox';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	title = 'Trang Chủ - Nghĩa Sinh Int\'l.';
	sponsors: any[] = [];
	collaborators: any[] = [];
	ttdds: any[] = [];
	tintucs: any[] = [];
	noisans: any[] = [];
	hinhanhs: any[] = [];

	// @ViewChild('owlElement', {read: any, static: true}) owlElement: OwlCarousel;

	sliderOptions = { margin: 20, dots: true, navigation: true, nav: false, navText:['<', '>'], autoplay: true, loop: true, lazyLoad: true, responsive:{ 0:{ items: 1 }, 600:{ items: 2 }, 1000:{ items: 5 } } };

	constructor(
		private db: DbApiService,
		// private _lightbox: Lightbox,
		private route: ActivatedRoute
	){
	}

	ngOnInit() {
		this.tintucs = sessionStorage.getItem('tintucs') ? JSON.parse(sessionStorage.getItem('tintucs')) : [];
		if (this.tintucs.length == 0){
    		this.getAllNews();
		}
		this.noisans = sessionStorage.getItem('noisans') ? JSON.parse(sessionStorage.getItem('noisans')) : [];
		if (this.noisans.length == 0){
    		this.getAllNoiSan();
		}
		this.hinhanhs = sessionStorage.getItem('images') ? JSON.parse(sessionStorage.getItem('images')) : [];
  		if (this.hinhanhs.length == 0){
      		this.getAllImages();
  		}

		this.ttdds = sessionStorage.getItem('ttdds') ? JSON.parse(sessionStorage.getItem('ttdds')) : [];
		if (this.ttdds.length == 0){
			this.getTTDDs();
		}
		this.sponsors = sessionStorage.getItem('home_htv') ? JSON.parse(sessionStorage.getItem('home_htv')) : [];
		if (this.sponsors.length == 0){
	    	this.getHTVs();
	    }
	    this.collaborators = sessionStorage.getItem('home_nbt') ? JSON.parse(sessionStorage.getItem('home_nbt')) : [];
		if (this.collaborators.length == 0){
    		this.getNBTs();
    	}
 
		// $.getScript('../../../assets/base/js/components.js');
		// $.getScript('../../../assets/base/js/components-shop.js');
		$.getScript('../../../assets/base/js/app.js');
		$.getScript('../../../assets/base/js/home.js');
		$.getScript('../../../assets/base/js/custom.js');

	}

 	ngAfterViewInit(){
	    window.scrollTo(0, 0);
	  }

	getAllNews() {
	 	 this.db.getAllNews()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.tintucs = data;
          sessionStorage.setItem('tintucs', JSON.stringify(data));
    		// console.log(this.tintucs);
      	});
  	}

  	getAllNoiSan() {
 		this.db.getAllNoiSan()
          .map((response: Response) => response.json())
          .subscribe(data => {
              this.noisans = data;
              sessionStorage.setItem('noisans', JSON.stringify(data));
      		// console.log(this.allnoisan);
      	});
	}

	getAllImages() {
  		this.db.getAllImages()
  		.subscribe( (response: Response) => {
      		this.hinhanhs = response.json();
      		// console.log(this.allImages);
	      	sessionStorage.setItem('images',JSON.stringify(this.hinhanhs));
  		});
  	}

	getTTDDs() {
		this.db.getTTDDs()
	  	.subscribe( (response: Response) => {
	      this.ttdds = response.json();
	      // console.log(this.ttdds);
	      sessionStorage.setItem('ttdds',JSON.stringify(this.ttdds));
	  });
	}
	getHTVs() {
		this.db.getHTVs()
	  	.subscribe( (response: Response) => {
	      this.sponsors = response.json();
	      sessionStorage.setItem('home_htv',JSON.stringify(this.sponsors));
	  	});
	}
	getNBTs() {
		this.db.getNBTs()
	  	.subscribe( (response: Response) => {
	      this.collaborators = response.json();
	      sessionStorage.setItem('home_nbt',JSON.stringify(this.collaborators));
	  	});
	}

}
