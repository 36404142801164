import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service'
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

@Component({
  selector: 'app-noisans',
  templateUrl: './noisans.component.html',
  styleUrls: ['./noisans.component.css']
})
export class NoisansComponent implements OnInit {
	allnoisan: any[] = [];
	pageHeader = 'Nội San Liên Nghĩa';
  pager: any = {}; // pager object
  pagedItems: any[]; // paged items
  pageSize = 60;
  currentPage = 0;
	constructor(
		private pagerService: PagerService,
		private db: DbApiService,
		private http: Http
	) { }

	ngOnInit() {
		this.allnoisan = sessionStorage.getItem('noisans') ? JSON.parse(sessionStorage.getItem('noisans')) : [];
		if (this.allnoisan.length == 0){
			// console.log('empty');
			this.getAllNoiSan();
		}else{
			this.setPage(1);
		}

    // $.getScript('../../../assets/base/js/components.js');
		$.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
	}

	getAllNoiSan() {
 		this.db.getAllNoiSan()
          .map((response: Response) => response.json())
          .subscribe(data => {
              // set items to json response
              this.allnoisan = data;
              sessionStorage.setItem('noisans', JSON.stringify(data));
      		// console.log(this.allnoisan);
      		
      		// initialize to page 1
				  this.setPage(1);
      	});
	}

	setPage(page: number) {
      	// get pager object from service
      	this.pager = this.pagerService.getPager(this.allnoisan.length, page, this.pageSize);
      	// console.log(this.pager);
        this.currentPage = page;
      	// get current page of items
     	this.pagedItems = this.allnoisan.slice(this.pager.startIndex, this.pager.endIndex + 1);
      	// console.log(this.pagedItems);
  	}

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
