import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

	pageTitle = 'About Us';
  	aboutus: any[] = [];
    noidungab: string;
  	constructor(
  		private db: DbApiService
  	) { }

  	ngOnInit() {
		  this.getAboutUs();

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	getAboutUs() {
  		this.db.getAboutUs()
  	  	.subscribe( (response: Response) => {
  	      this.aboutus = response.json();
  	      this.noidungab = this.aboutus[0]['noidung'];
  	      // console.log(this.aboutus);
  	  });
	  }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
