import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-namdieu',
  templateUrl: './namdieu.component.html',
  styleUrls: ['./namdieu.component.css']
})
export class NamdieuComponent implements OnInit {
	namdieus: any[] = [];
	noidung: string;
  	pageHeader = '';

  	constructor(
  		private db: DbApiService
  	) { }

  	ngOnInit() {
		this.getNamDieu();

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	getNamDieu() {
		this.db.getNamDieu()
	  	.subscribe( (response: Response) => {
	      this.namdieus = response.json();
	      this.noidung = this.namdieus[0]['noidung'];
	      this.pageHeader = this.namdieus[0]['tieude'];
	      // console.log(this.namdieus);
	  	});
	}

	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}
}
