import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-tuyentap',
  templateUrl: './tuyentap.component.html',
  styleUrls: ['./tuyentap.component.css']
})
export class TuyentapComponent implements OnInit {

 	pageHeader = 'Tuyển tập nghĩa Ca';
  	constructor() { }

  	ngOnInit() {
      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');
  	}

  	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}
}
