import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

declare var $: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  	pageHeader = 'CÁC FILE Video';
	allVideos: any[] = [];

	constructor(
		private pagerService: PagerService,
  		private db: DbApiService,
  		private http: Http
	) { }

  ngOnInit() {
  	this.allVideos = sessionStorage.getItem('videos') ? JSON.parse(sessionStorage.getItem('videos')) : [];
  	if (this.allVideos.length == 0){
  	  // console.log('empty');
  	  this.getAllVideos();
  	}

    // $.getScript('../../../assets/base/js/components.js');
	  $.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  }

  	getAllVideos() {
	 	this.db.getVideos()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.allVideos = data;
          sessionStorage.setItem('videos', JSON.stringify(data));
    		// console.log(this.allVideos);
    		
      	});
  	}
    
    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
