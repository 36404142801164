import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
declare var $: any;
// import { Tintuc } from '../api/classes';

@Component({
  selector: 'app-tintuc',
  templateUrl: './tintuc.component.html',
  styleUrls: ['./tintuc.component.css']
})
export class TintucComponent implements OnInit {
	pageHeader = 'Tin tức hội họp';
	ttId = '';
	tintuc: any[] = [];
	errorMessage: String;
   	dataAvailableById= true;
   	hinhanh = '';
   	noidung = '';
   	tieude = '';
   	bidanh = '';
   	ngaydt = '';
   	hit = '';
   	tomtat = '';
   	chuthich = '';
   	tintucs = [];
   	authorNews = [];
  	constructor(
  		private route: ActivatedRoute,
		private db: DbApiService,
		private http: Http
  	) { }

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
	      this.ttId = params['id'];
	      this.getTintucById(this.ttId);
	      this.tintucs = sessionStorage.getItem('tintucs') ? JSON.parse(sessionStorage.getItem('tintucs')): [];
	      // console.log(this.tintucs);
	      if (this.tintucs.length == 0){
	        this.getAllNews();
	      }
	    });

	    // $.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/single-page.js');

	}

  	ngAfterViewInit(){
		window.scrollTo(0, 0);
	}

  	getAllNews() {
 	  	this.db.getAllNews()
      	.map((response: Response) => response.json())
      	.subscribe(data => {
          // set items to json response
          this.tintucs = data;
          sessionStorage.setItem('tintucs', JSON.stringify(data));
    		// console.log(this.allnews);
      	});
  	}

	getTintucById(id: string) {
	 	this.db.getTintucUpdateById(id)
	      .map((response: Response) => response.json())
	      .subscribe(data => {
	        // set items to json response
	        this.tintuc = data[0];
	        this.tieude = this.tintuc['tieude'];
	        this.hinhanh = this.tintuc['ten_hinh'];
	        this.noidung = this.tintuc['noidung'];
	        // console.log(this.noidung);
	        let checkStrImg = this.noidung.indexOf('<img src="/userfiles/');
	        if (checkStrImg > 0){
	        	this.noidung = this.noidung.replace(/<img src="\/userfiles\//g, '<p class=\'col-md-12 col-xs-12 div-img text-center\'><img class="img-responsive" src="http://www.nghiasinh.org/userfiles/');
	        }
	        let checkCloseImgTag = this.noidung.indexOf('height="');
	        // console.log(checkCloseImgTag);
	        // if (checkCloseImgTag > 0){
	        // 	this.noidung = this.noidung.replace(/">/g,'"></p>');
	        // }

	        let checkLink = this.noidung.indexOf('http://www.nghiasinh.org/?mode=new_nd&amp;stt=');
	        if (checkLink > 0){
	        	// console.log(checkLink)
	        	this.noidung = this.noidung.replace(/http:\/\/www.nghiasinh.org\/\?mode=new_nd&amp;stt=/g, 'http://www.nghiasinh.net/#/tintuc/');
	        }
	        this.bidanh = this.tintuc['bidanh'];
	        this.ngaydt = this.tintuc['ngaydt'];
	        this.hit = this.tintuc['hit'];
	        this.chuthich = this.tintuc['chuthich'];
	        this.tomtat = this.tintuc['tomtat'];
	        // console.log(this.tintucs);
	        this.authorNews = this.tintucs.filter(ft => ft['bidanh'] == this.bidanh);
	        // console.log(this.authorNews);


	  	});
	}

}
