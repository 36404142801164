import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { DbApiService } from '../_services/dbapi.service';
import { PagerService } from '../_services/pager.service'
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

declare var $: any;

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {
    allImages: any[] = [];
	  allAlbums: any[] = [];
  	pageHeader = 'Các Albums HÌnh Ảnh';
    pager: any = {}; // pager object
    pagedItems: any[]; // paged items
    pageSize = 60;
    tintucArr = [];
    currentPage = 0;
    newAlbumImage = [];
  	constructor(
  		private pagerService: PagerService,
  		private db: DbApiService,
  		private http: Http
  	) { }

  	ngOnInit() {
  		this.allAlbums = sessionStorage.getItem('albums') ? JSON.parse(sessionStorage.getItem('albums')) : [];
  		if (this.allAlbums.length == 0){
			  // console.log('empty');
			  this.getAllAlbums();
  		}else{
        this.setPage(1);
			  // this.getAllAlbumsImg();
  		}

      // $.getScript('../../../assets/base/js/components.js');
  		$.getScript('../../../assets/base/js/big-breadcrumbs-header.js');
  	}

  	getAllAlbums() {
	 	  this.db.getAllAlbums()
      .map((response: Response) => response.json())
      .subscribe(data => {
          // set items to json response
          this.allAlbums = data;
          sessionStorage.setItem('albums', JSON.stringify(data));
      		// console.log(this.allAlbums);
      		// this.getAllAlbumsImg();
      		this.setPage(1);
      });
	  }

    getAllAlbumsImg(){
      this.allImages = sessionStorage.getItem('images') ? JSON.parse(sessionStorage.getItem('images')) : [];
      if (this.allImages.length == 0){
          this.getAllImages();
      }else{
        this.getAlbumImage();
      }
    }
    
    getAllImages() {
      this.db.getAllImages()
      .subscribe( (response: Response) => {
          this.allImages = response.json();
          // console.log(this.allImages);
          sessionStorage.setItem('images',JSON.stringify(this.allImages));
          this.getAlbumImage();
      });
    }

    getAlbumImage(){
      for (var a = 0; a < this.allAlbums.length; ++a) {
        for (var i = 0; i < this.allImages.length; ++i) {
          if (this.allAlbums[a]['id_album'] == this.allImages[i]['id_album']){
            var tempArr = this.allAlbums[a];
            tempArr['email'] = this.allImages[i]['email'];
            tempArr['tenfile'] = this.allImages[i]['tenfile'];
            // console.log(tempArr);
            this.newAlbumImage.push(tempArr);
            break;
          }
        }
      }

      this.setPage(1);
    }

	  setPage(page: number) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.allAlbums.length, page, this.pageSize);
        this.currentPage = page;
        // console.log(page);

        // get current page of items
        this.pagedItems = this.allAlbums.slice(this.pager.startIndex, this.pager.endIndex + 1);
        // console.log(this.pagedItems);
    }

    ngAfterViewInit(){
      window.scrollTo(0, 0);
    }
}
