import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DbApiService } from '../_services/dbapi.service';
declare var $: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  alldas: any[] = [];
  pageHeader = 'Dự Án Tương Lai';
  daTen: string;
  noidung: string;
  hinhanh: string;
  chuthich: string;
  ngay: string;
  daId: number;
  daArr: any[] = [];
  selectedDA: any[] = [];
  duanArr = [];
  constructor(
  	private route: ActivatedRoute,
	  private db:DbApiService, 
	  private http: Http
  ) { }

  ngOnInit() {
  	this.route.params.subscribe((params: Params) => {
      this.daId = params['id'];
      // console.log('get from  link: '+this.daId);
    	this.daArr = sessionStorage.getItem('duan') ? JSON.parse(sessionStorage.getItem('duan')) : [];
    	if (this.daArr.length == 0){
    	  // console.log('empty');
    	  this.getAllDuAn();
    	}else{
    	// console.log('not empty');
    		// console.log(this.daArr);
    		this.alldas = this.daArr;
    		this.daId = this.getDAId();
    		this.renderDuAn(this.daId);
    	}
    });
    // $.getScript('../../../assets/base/js/components.js');
  	$.getScript('../../../assets/base/js/single-page.js');
  }

  getAllDuAn() {
	  	this.db.getAllDuAn()
    	.map((response: Response) => response.json())
    	.subscribe(data => {
      	// set items to json response
      	this.alldas = data;
      	sessionStorage.setItem('duan', JSON.stringify(data));
    		// console.log(this.alldas);
  			this.daId = this.getDAId();
  			this.renderDuAn(this.daId);
    	});
	}

 	renderDuAn(id){
    this.duanArr = [];     
 		// console.log(id);
 		for (var d = 0; d < this.alldas.length; ++d) {
 			if (this.alldas[d]['id_hd'] == id){
 				this.selectedDA = this.alldas[d];
 				this.daTen = this.selectedDA['ten'];
		  	this.noidung = this.selectedDA['thongtin'];
		  	this.hinhanh = this.selectedDA['hinhanh'];
		  	this.chuthich = this.selectedDA['chuthich'];
		  	this.ngay = this.selectedDA['ngay'];
 			}else{
         this.duanArr.push(this.alldas[d]);
      }
 		}
 	}

 	getDAId(){
 		if (this.daId == 0){
        	return this.alldas[0]['id_hd'];
        }else{
        	return this.daId;
        }
 	}

  ngAfterViewInit(){
    window.scrollTo(0, 0);
  }

}
